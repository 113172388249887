<template>
    <div class="modal-body">
        <div class="modal-body__close" @click="$emit('close')">
            <img src="@/assets/images/icons/x.svg" alt="close">
        </div>

        <h3 class="modal-body__title">Передача открытых вакансий</h3>
        <p class="modal-body__description">Открытые вакансии, отклики и чаты будут переданы другому исполнителю</p>

        <VSelect v-model="employee"
                 placeholder="Выберите исполнителя*"
                 :options="similarEmployees"
                 labelFild="full_name"
                 :error="errorSelect && !employee"
                 :empty="!similarEmployees.length"
                 emptyText="В компании нет сотрудников данного типа"
                 class="modal-body__select"/>

        <p v-if="errorSelect && !employee" class="modal-body__error-text">Заполните обязательное поле</p>

        <div class="modal-body__btns">
            <VButton
                label="Удалить и передать вакансии"
                bg="#DD4646"
                bgHover="#D10E0E"
                color="#fff"
                class="modal-body__btn"
                @click="deleteAndTransfer"/>

            <VButton
                label="Отменить"
                class="modal-body__btn"
                bg="#E4E7EE"
                bgHover="#BBC4CD"
                color="#1E3959"
                colorHover="#1E3959"
                @click="$emit('close')"/>
        </div>
    </div>
</template>

<script setup>
import {onMounted, ref} from "vue";

const props = defineProps({
    similarEmployees: Array
})

const emit = defineEmits(['deleteAndTransfer'])

const employee = ref(null)
const errorSelect = ref(false)

const deleteAndTransfer = () => {
    if(!employee.value) {
        errorSelect.value = true
        return
    }
    errorSelect.value = false
    emit('deleteAndTransfer', employee.value)
}
</script>

<style scoped lang="scss">
.modal-body {
    position: relative;
    padding: 40px 24px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &__close {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
    }

    &__title {
        color: $dark;
        font-size: 20px;
        font-weight: 600;
    }

    &__description {
        margin-top: 8px;
        font-size: 16px;
        font-weight: 400;
    }

    &__select {
        width: 100%;
        margin-top: 16px;
    }

    &__error-text {
        width: 100%;
        text-align: left;
        margin-top: 4px;
        color: $red;
        font-size: 14px;
    }

    &__btns {
        width: 100%;
        margin-top: 24px;
    }

    &__btn {
        width: 100%;
        padding: 0;

        &:first-child {
            &:active {
                background-color: #A61F1F !important;
            }
        }

        &:last-child {
            &:active {
                background-color: #8F9CAC !important;
            }
        }

        &:not(:first-child) {
            margin-top: 12px;
        }
    }
}
</style>
