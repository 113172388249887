<template>
    <div class="modal-body">
        <div class="modal-body__close" @click="$emit('close')">
            <img src="@/assets/images/icons/x.svg" alt="close">
        </div>

        <h3 class="modal-body__title">Закрытие открытых вакансий</h3>
        <p class="modal-body__description">Все открытые вакансии и чаты будут закрыты, по откликам будет отправлен
            отказ</p>

        <div class="modal-body__block">
            <p class="modal-body__block-label">Для откликнувшихся:</p>
            Благодарим за ваш отклик.
            Мы внимательно ознакомились с вашим резюме. К сожалению, на данном этапе мы не готовы предложить вам работу.
            Искренне желаем удачи в поисках работы!
        </div>

        <div class="modal-body__btns">
            <VButton label="Удалить и закрыть вакансии" bg="#DD4646" bgHover="#D10E0E" color="#fff"
                class="modal-body__btn" @click="$emit('deleteAndClose')" />

            <VButton label="Отменить" class="modal-body__btn" bg="#E4E7EE" bgHover="#BBC4CD" color="#1E3959"
                colorHover="#1E3959" @click="$emit('close')" />
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
    similarEmployees: Array
})

const employee = ref(null)

const options = [
    {
        id: 1,
        label: 'Тест'
    },
    {
        id: 2,
        label: 'Тест 2'
    },
]
</script>

<style scoped lang="scss">
.modal-body {
    position: relative;
    padding: 40px 24px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &__close {
        position: absolute;
        top: 16px;
        right: 16px;
        cursor: pointer;
    }

    &__title {
        color: $dark;
        font-size: 20px;
        font-weight: 600;
    }

    &__description {
        margin-top: 8px;
        font-size: 16px;
        font-weight: 400;
    }

    &__label {
        width: 100%;
        margin-top: 16px;
        color: $gray;
        font-size: 14px;
        font-weight: 500;
        text-align: left;
    }

    &__block {
        width: 100%;
        padding: 16px;
        margin-top: 16px;
        border-radius: 8px;
        background-color: $light;
        color: $gray;
        font-size: 14px;
        text-align: left;
    }

    &__block-label {
        margin-bottom: 4px;
        color: $dark-blue;
        font-size: 12px;
        font-weight: 400;
    }

    &__select {
        width: 100%;
        margin-top: 16px;
    }

    &__btns {
        width: 100%;
        margin-top: 24px;
    }

    &__btn {
        width: 100%;

        &:first-child {
            padding: 0 10px !important;

            &:active {
                background-color: #A61F1F !important;
            }
        }

        &:last-child {
            &:active {
                background-color: #8F9CAC !important;
            }
        }

        &:not(:first-child) {
            margin-top: 12px;
        }
    }
}
</style>
