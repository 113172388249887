<template>
    <div class="create-employee">
        <div class="create-employee__wrapper container">

            <div class="create-employee__header">
                <div class="create-employee__header-breadcrumbs">
                    <div class="create-employee__header-breadcrumb">
                        <router-link to="/companies" class="create-employee__header-breadcrumb-name"
                            v-if="role === 'SUPER_ADMIN'">
                            Компании
                        </router-link>

                        <router-link to="/employees" class="create-employee__header-breadcrumb-name"
                            v-if="role === 'MAIN_HR'">
                            Сотрудники
                        </router-link>
                        <img src="@/assets/images/icons/arrow-right-gray.svg"
                            class="create-employee__header-breadcrumb-arrow" alt="arrow" />
                    </div>
                    <div class="create-employee__header-breadcrumb" v-if="role === 'SUPER_ADMIN'">
                        <router-link :to="`/companies/${route.params.id}`"
                            class="create-employee__header-breadcrumb-name">
                            Карточка компании
                        </router-link>
                        <img src="@/assets/images/icons/arrow-right-gray.svg"
                            class="create-employee__header-breadcrumb-arrow" alt="arrow" />
                    </div>
                    <div class="create-employee__header-breadcrumb">
                        <p class="create-employee__header-breadcrumb-name" v-if="editing">
                            Редактировать сотрудника
                        </p>
                        <p class="create-employee__header-breadcrumb-name" v-else>
                            Новый сотрудник
                        </p>
                        <img src="@/assets/images/icons/arrow-right-gray.svg"
                            class="create-employee__header-breadcrumb-arrow" alt="arrow" />
                    </div>
                </div>
                <div class="create-employee__header-row">
                    <h1 class="create-employee__header-title main-title" v-if="!editing">Новый сотрудник</h1>
                    <h1 class="create-employee__header-title main-title" v-else>Редактировать сотрудника</h1>
                    <VButton label="Удалить сотрудника" autoSize bg="transparent" bgHover="#E4E7EE" color="#DD4646"
                        colorHover="#DD4646" @click="deleteEmployee" v-if="editing && userId !== empoleeId"
                        class="create-employee__delete-btn">
                        <template #iconLeft>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M5 7.3335H6.55556H19" stroke="#DD4646" stroke-linecap="round"
                                    stroke-linejoin="round" />
                                <path
                                    d="M17.4446 7.33328V18.2222C17.4446 18.6347 17.2807 19.0304 16.9889 19.3221C16.6972 19.6138 16.3016 19.7777 15.889 19.7777H8.11122C7.69866 19.7777 7.303 19.6138 7.01128 19.3221C6.71955 19.0304 6.55566 18.6347 6.55566 18.2222V7.33328M8.889 7.33328V5.77772C8.889 5.36516 9.05289 4.9695 9.34461 4.67778C9.63633 4.38606 10.032 4.22217 10.4446 4.22217H13.5557C13.9682 4.22217 14.3639 4.38606 14.6556 4.67778C14.9473 4.9695 15.1112 5.36516 15.1112 5.77772V7.33328"
                                    stroke="#DD4646" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </template>
                    </VButton>
                </div>
            </div>

            <MainBlock class="create-employee__main-block" :validation="validation" :errorEmailText="errorEmailText"
                :editing="!!route.params.employeeId" @editPassword="passwordValid = true" v-model="form" />

            <SettingsBlock class="create-employee__settings-block" :validation="validation" :errorText="errorRoleText"
                :hideUserAccess="userId === empoleeId" v-model="form" />

            <div class="create-employee__btns">
                <VButton v-if="editing" label="Сохранить" clickEffect :disabled="disabled" class="create-employee__btn"
                    @click="submit" />

                <VButton v-else label="Добавить" :disabled="disabled" clickEffect class="create-employee__btn"
                    @click="submit" />

                <VButton label="Отменить" class="create-employee__btn" bg="#E4E7EE" color="#1E3959" disableHover
                    @click="showPopup = true" />
            </div>

            <ModalWindow v-if="showPopup" @close="showPopup = false" class="create-employee__modal">
                <CancelChanges @close="showPopup = false" @cancelChanges="cancelChanges" />
            </ModalWindow>

            <ModalWindow v-if="showDeletePopup" @close="showDeletePopup = false" class="create-employee__modal-delete">
                <DeletingEmployee title="Удаление Главного HR-менеджера"
                    description="Вы уверены что хотите удалить Главного HR-менеджера?" @close="showDeletePopup = false"
                    @no="showDeletePopup = false" @delete="deleteEmployeeRequest" />
            </ModalWindow>

            <ModalWindow v-if="showDeletingChangeEmployee" @close="showDeletingChangeEmployee = false"
                class="create-employee__modal-delete">
                <DeletingChangeEmployee :role="employeeRole" @close="showDeletingChangeEmployee = false"
                    @changeArtist="openTransferVacancies" @closingOpenVacancies="openShowClosingOpenVacancies" />
            </ModalWindow>

            <ModalWindow v-if="showTransferVacancies" @close="showTransferVacancies = false"
                class="create-employee__modal-delete">
                <TransferVacancies :similarEmployees="similarEmployees" @close="showTransferVacancies = false"
                    @deleteAndTransfer="deleteAndTransfer" />
            </ModalWindow>

            <ModalWindow v-if="showClosingOpenVacancies" @close="showClosingOpenVacancies = false"
                class="create-employee__modal-closing-open">
                <ClosingOpenVacancies @deleteAndClose="deleteAndClose" @close="showClosingOpenVacancies = false" />
            </ModalWindow>
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, reactive, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import other from "@/api/other";
import employee from "@/api/employee";

import CancelChanges from '@/components/ModalWindow/ModalBodyes/CancelChanges'
import DeletingChangeEmployee from '@/components/ModalWindow/ModalBodyes/DeletingChangeEmployee'
import DeletingEmployee from '@/components/ModalWindow/ModalBodyes/DeletingEmployee'
import TransferVacancies from '@/components/ModalWindow/ModalBodyes/TransferVacancies'
import ClosingOpenVacancies from '@/components/ModalWindow/ModalBodyes/ClosingOpenVacancies'
import MainBlock from '@/components/CreateEmployeeAndAdmin/MainBlock'
import SettingsBlock from '@/components/CreateEmployeeAndAdmin/SettingsBlock'

const router = useRouter();
const route = useRoute();
const routes = route.matched
const store = useStore();

const role = store.getters["auth/role"];
const editing = !!route.params.employeeId
const passwordValid = ref(false)

const errorRoleText = ref('')
const errorEmailText = ref('')

const showPopup = ref(false)
const showDeletePopup = ref(false)
const showDeletingChangeEmployee = ref(false)
const showTransferVacancies = ref(false)
const showClosingOpenVacancies = ref(false)

const userId = ref(null)
const empoleeId = ref(null)
const employeeRole = ref('')

const form = reactive({
    name: '',
    email: '',
    password: '',
    employeeType: '',
    jobTitle: '',
    access: 0
})

const disabled = ref(false)

const rules = computed(() => ({
    name: { required },
    email: { required },
    password: { requiredPassword },
    employeeType: { required },
    jobTitle: { requiredJobTitle }
}));

const requiredPassword = (val) => {
    if (editing && !passwordValid.value) return true
    else return !!val
}

const requiredJobTitle = (val) => {
    if (form.employeeType !== 'APPROVING_PERSON') return true
    else return !!val?.trim()?.length
}


const v$ = useVuelidate(rules, form);
const validation = ref(false)

onMounted(() => {
    getUser()
    getEmployee()
})

const getUser = async () => {
    try {
        const response = await other.getUser()
        if (response.blocked_by_contract) await router.push('/')
        userId.value = response.id
    } catch (error) {
        console.log(error)
    }
}

const similarEmployees = ref([])

const getEmployee = async () => {
    if (!editing) return

    try {
        const response = await employee.getEmployee(route.params.employeeId, route.params.id)
        empoleeId.value = response.id
        form.email = response.email
        form.name = response.full_name
        form.employeeType = response.role
        form.jobTitle = response.job_title
        form.access = response.is_blocked
        employeeRole.value = response.role
        similarEmployees.value = response.similar_employees
    } catch (error) {
        console.log(error)
    }
}

const submit = async () => {
    errorRoleText.value = ''
    errorEmailText.value = ''

    const validate = await v$.value.$validate();
    if (!validate) {
        validation.value = true
        return
    }
    validation.value = false
    disabled.value = true

    const body = {
        full_name: form.name,
        email: form.email,
        password: form.password,
        is_blocked: form.access,
        role: form.employeeType,
        job_title: form.jobTitle,
    }

    try {
        if (!editing) {
            if (role === 'SUPER_ADMIN') {
                const response = await employee.postEmployee({
                    company_id: route.params.id
                }, body)

                if (response.blocked_by_contract) await router.push('/')
            }
            if (role === 'MAIN_HR') {
                const response = await employee.postEmployee({}, body)

                if (response.blocked_by_contract) await router.push('/')
            }
        } else {
            if (role === 'SUPER_ADMIN') {
                const response = await employee.putEmployee(route.params.employeeId, {
                    company_id: route.params.id
                }, body)

                if (response.blocked_by_contract) await router.push('/')
            }
            if (role === 'MAIN_HR') {
                const response = await employee.putEmployee(route.params.employeeId, {}, body)

                if (response.blocked_by_contract) await router.push('/')
            }
        }
        if (role === 'SUPER_ADMIN') await router.push(`/companies/${route.params.id}`);
        if (role === 'MAIN_HR') await router.push(`/employees`);
    } catch (error) {
        console.log(error)
        disabled.value = false
        if (error?.response?.data?.errors?.role) errorRoleText.value = error.response.data.errors.role[0];
        if (error?.response?.data?.errors?.email) errorEmailText.value = error.response.data.errors.email[0];
        if (error?.response?.data?.error_message) errorEmailText.value = error.response.data.error_message
    }
}

const deleteEmployee = () => {
    if (form.employeeType === 'MAIN_HR') {
        showDeletePopup.value = true
    } else {
        showDeletingChangeEmployee.value = true
    }
}

const deleteEmployeeRequest = async () => {
    try {
        if (role === 'SUPER_ADMIN') {
            const response = await employee.deleteEmployee(route.params.employeeId, {
                company_id: route.params.id
            })

            if (response.blocked_by_contract) await router.push('/')
        }
        if (role === 'MAIN_HR') {
            const response = await employee.deleteEmployee(route.params.employeeId)
            if (response.blocked_by_contract) await router.push('/')
        }

        if (role === 'SUPER_ADMIN') await router.push(`/companies/${route.params.id}`);
        if (role === 'MAIN_HR') await router.push(`/employees`);
    } catch (error) {
        console.log(error)
    }
}

const cancelChanges = () => {
    if (role === 'SUPER_ADMIN') {
        router.push(`/companies/${route.params.id}`);
    }

    if (role === 'MAIN_HR') {
        router.push('/employees');
    }
}

const openTransferVacancies = () => {
    showDeletingChangeEmployee.value = false
    showTransferVacancies.value = true
}
const openShowClosingOpenVacancies = () => {
    showDeletingChangeEmployee.value = false
    showClosingOpenVacancies.value = true
}
const deleteAndTransfer = async (val) => {
    showTransferVacancies.value = false
    try {
        const response = await employee.deleteAndTransfer(empoleeId.value, val, route.params.id)
        if (response.blocked_by_contract) {
            await router.push('/')
            return
        }
        if (response.success) {
            if (role === 'SUPER_ADMIN') {
                await router.push(`/companies/${route.params.id}`)
            }
            if (role === 'MAIN_HR') {
                await router.push(`/employees`)
            }
        }
    } catch (error) {
        console.log(error)
    }

}

const deleteAndClose = async (val) => {
    showTransferVacancies.value = false
    try {
        const response = await employee.deleteAndClose(empoleeId.value, route.params.id, {
            rejection_reason: 'Благодарим за ваш отклик. Мы внимательно ознакомились с вашим резюме. К сожалению, на данном этапе мы не готовы предложить вам работу. Искренне желаем удачи в поисках работы!'
        })

        if (response.success) {
            if (role === 'SUPER_ADMIN') {
                await router.push(`/companies/${route.params.id}`)
            }
            if (role === 'MAIN_HR') {
                await router.push(`/employees`)
            }
        }
    } catch (error) {
        console.log(error)
    }

}
</script>

<style scoped lang="scss">
.create-employee {
    &__wrapper {
        padding-top: 12px;
        min-height: 100vh;
    }

    &__header {}

    &__header-breadcrumbs {
        display: flex;
        align-items: center;
    }

    &__header-breadcrumb {
        display: flex;
        align-items: center;

        &:not(:first-child) {
            margin-left: 4px;
        }
    }

    &__header-breadcrumb-name {
        font-size: 12px;
        color: $gray;
        text-decoration: none;
    }

    &__header-breadcrumb-arrow {
        margin-top: 1px;
        margin-left: 4px;
        width: 12px;
        height: 12px;
    }

    &__header-breadcrumb:last-child &__header-breadcrumb-arrow {
        display: none;
    }

    &__header-row {
        margin-top: 12px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    &__delete-btn {
        height: 32px;
        padding: 0 8px;
        font-size: 14px !important;
        font-weight: 500 !important;

        &:active {
            background-color: $light-gray-2 !important;
        }
    }

    &__main-block {
        margin-top: 24px;
    }

    &__settings-block {
        margin-top: 24px;
    }

    &__btns {
        width: 100%;
        margin-top: 32px;
        display: flex;
        justify-content: space-between;
    }

    &__btn {
        &:first-child {
            width: 53.846%;

            &:hover {
                background-color: $light-gray-2 !important;
            }

            &:active {
                background-color: $gray !important;
            }
        }

        &:last-child {
            width: 42.307%;
        }
    }

    &__modal {
        &::v-deep(.modal__body) {
            width: 304px;
        }
    }

    &__modal-delete {
        &::v-deep(.modal__body) {
            width: 304px;
        }
    }

    &__modal-closing-open {
        &::v-deep(.modal__body) {
            width: 304px;
        }
    }
}
</style>
